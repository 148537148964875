@use "../0-settings/colors";
@use "../0-settings/core";

.c-ico-separator {
  position: relative;
  width: 100%;
  height: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: core.$baseline;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: .15em;
    background: colors.$color-black;
  }

  .c-ico-separator__icon {
    z-index: 1;
    padding: .3em;
    background-color: colors.$color-white;

    svg {
      width: 3em;
      height: 3em;
      fill: colors.$color-turquoise;
    }
  }
}
