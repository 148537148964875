.o-skip-link {
  /* moves off screen */
  position: absolute;
  top: 0;
  right: 100%;
}

.o-skip-link:focus {
  right: auto;
}
