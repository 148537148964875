@use "../0-settings/colors";
@use "../0-settings/core";

.c-hero {
  padding: core.$baseline 0;
  background-color: colors.$color-turquoise-dark;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.c-hero--sequoia {
  // Image generated at https://www.responsivebreakpoints.com/
  // Settings:
  // Resolution: 200 to 1920
  // Size step : 20KB
  // Maximum images : 10

  @media only screen and (min-width: 320px) and ( max-width: 517px) {
    background-image: url(/assets/img/hero/hero_image,w_517.jpg);
  }

  @media only screen and (min-width: 518px) and (max-width: 858px) {
    background-image: url(/assets/img/hero/hero_image,w_858.jpg);
  }

  @media only screen and (min-width: 859px) and (max-width: 958px) {
    background-image: url(/assets/img/hero/hero_image,w_958.jpg);
  }

  @media only screen and (min-width: 959px) and (max-width: 1056px) {
    background-image: url(/assets/img/hero/hero_image,w_1056.jpg);
  }

  @media only screen and (min-width: 1057px) and (max-width: 1242px) {
    background-image: url(/assets/img/hero/hero_image,w_1242.jpg);
  }

  @media only screen and (min-width: 1243px) and (max-width: 1766px) {
    background-image: url(/assets/img/hero/hero_image,w_1766.jpg);
  }

  @media only screen and (min-width: 1767px) and (max-width: 1920px) {
    background-image: url(/assets/img/hero/hero_image,w_1920.jpg);
  }
}
