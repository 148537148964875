@use "../0-settings/colors";

.o-pagination {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: center;
  padding-left: 0;
}

  .o-pagination__item {
    padding-left: .3em;
    padding-right: .3em;
  }
  // Overrides default margin.
  .o-pagination__item + .o-pagination__item {
    margin: 0
  }

    .o-pagination__link {
      &[aria-current="page"] {
        padding: .3em;
        border: colors.$color-blue-dark solid .1em;
        border-radius: .3em;
      }
    }
