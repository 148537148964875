@use "../0-settings/core";
@use "../1-tools/mixins";

.o-media {
  display:flex;
  align-items:flex-start;
  margin-bottom: core.$baseline;
}

  .o-media__figure {
    margin-right:1em;
  }

  .o-media__body {
    flex:1;

    @include mixins.strip-leading-trailing-margins;
  }

.o-media--in-middle {
  align-items: center;
}
