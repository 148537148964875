@use "sass:math";

@use "../0-settings/colors";
@use "../0-settings/core";

.c-footnotes {
	margin-top: 3*core.$baseline;
	padding: math.div(core.$baseline, 3) core.$baseline;
	background-color: colors.$color-gray-light;

	ol {
		font-size: .875rem;
	}
	main > &:last-child {
		margin-bottom: 0; // Removes one inherited from being main last child.
	}
}


	.c-footnotes__heading {
		font-size: core.$h6;
		line-height: core.$h6;
	}

a[href^="#footnote-"] {
	// Style borrowed from normalize.
	position: relative;
	top: -.5em;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

li[id^="footnote"]:target {
	background-color: #ffe855;
	padding: .3em;

	a:focus {
		outline-color: colors.$color-turquoise;
		padding: .3em;
		background-color: colors.$color-gray-light;
	}
}
