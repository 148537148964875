@use "../0-settings/colors";

[type="date"],
[type="email"],
[type="tel"],
[type="text"],
textarea {
  width: 100%;
  padding: .25em;
  -webkit-appearance: none;
  border: 2px solid colors.$color-turquoise-dark;
}

select {
	width: 100%;
}

[type=text],
[type=tel],
[type=email],
[type=date],
[type=radio],
[type=checkbox],
[type=file],
[type=search],
[type=submit],
button,
textarea,
select {
  &:focus {
    outline: 0.125rem solid colors.$color-yellow;
  }
}

fieldset {
  border: none;
  padding: 0;
}
