@use "../0-settings/core";

.u-t-xxx-large {
  font-size: core.$font-size-text-xxx-large;
}

.u-t-xx-large {
  font-size: core.$font-size-text-xx-large;
}

.u-t-x-large {
  font-size: core.$font-size-text-x-large;
}

.u-t-large {
  font-size: core.$font-size-text-large;
}

.u-t-medium {
  font-size: core.$font-size-text-medium;
}

.u-t-small {
  font-size: core.$font-size-text-small;
}

.u-t-x-small {
  font-size: core.$font-size-text-x-small;
}
