.c-logo {
  display: block;
  border-width: 0;

  &:hover {
    border-width: 0;
  }

  svg {
    width: 12em;
    height: auto;
  }
}

