$color-black-light: #242424;
$color-white: #fefefd;
$color-black: #010101;
$color-red: #9D3342;
$color-blue: #2f70b4;
$color-blue-dark: #2A4ACB;
$color-blue-light: #e2e8f0;
$color-yellow: #ffbf47;
$color-gray: #636d72;
$color-gray-light: #f7f7f7;
$color-gray-lighter: #eeeeee;
$color-gray-dark: #454545;
$color-turquoise: #2A8271;
$color-turquoise-dark: #206356;
$color-turquoise-light: #a2ddd2;

$heading-color-h1: $color-turquoise;
$heading-color-h2: $color-black;
$heading-color-h3: $color-black;
$heading-color-h4: $color-black;
$heading-color-h5: $color-black;
$heading-color-h6: $color-black;
$link-color: $color-blue-dark;
$link-underline-color: $color-turquoise;
$focus-color: $color-yellow;
$color-brand: $color-turquoise;
