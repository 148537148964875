@use "../0-settings/colors";

.c-ico-square {
  width: 5em;
  height: 5em;
  padding: .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: colors.$color-turquoise;

  svg {
    width: 3em;
    height: 3em;
    fill: colors.$color-white;
  }
}
