@use "../0-settings/colors";
@use "../0-settings/core";

.c-site-header {
  margin-bottom: 0;
  background: colors.$color-white;
}
  .c-site-header__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }

    .c-site-header__logo {
      padding: core.$baseline*0.5 core.$baseline core.$baseline*0.5 0;
      white-space: nowrap;
      font-size: core.$base-min;
    }

    .c-site-header__nav {
      flex-grow: 2;

      a,
      a:hover,
      a:visited {
        color: colors.$color-black;
      }
    }
