@use "../0-settings/colors";
@use "../0-settings/core";
@use "../1-tools/mixins";

.o-box {
  padding: core.$baseline;

  @include mixins.strip-leading-trailing-margins;
}

.o-box--hero {
	color: colors.$color-white;
  background-color: rgba(colors.$color-brand, .9);

  h1 {
    color: colors.$color-white;
  }
}
