@use "../0-settings/colors";
@use "../0-settings/core";

.cards {
    list-style: none;
    margin: 0 auto 2*core.$baseline;
    padding: 0;
    max-width: 40em;
}

  .card {
      border-radius: .25rem;
      box-shadow: gray 1px 2px 7px 2px;
      padding: 1.5em;
      margin-bottom: 3rem;

      // Heading
      header > *:first-child {
        margin-top: 0;
        padding: .5em;
        border-radius: .25em;
        background-color: colors.$color-brand;
        color: colors.$color-white;
      }

      em {
        color: colors.$color-gray;
        font-size: 1.2em;
      }
       
      footer {
        display: flex;
        justify-content: center;
        margin-top: 2*core.$baseline;
        margin-bottom: 0;
      }
  }

  .card__price {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: core.$baseline;
  }

  .card__subsection {
    margin-bottom: core.$baseline;

    > *:first-child {
      display: block;
      margin-bottom: .5em;
    }
  }
