@use "../0-settings/core";
@use "../1-tools/mixins";

header,
footer {
  margin-bottom: core.$baseline;
}

main {
  // Pushes the footer down for tiny content pages.
  min-height: 60vh;
  & > *:last-child {
	margin-bottom: 3*core.$baseline;
  }
}

nav {
  @include mixins.strip-leading-trailing-margins;
}
