@use "../0-settings/core";
@use "../1-tools/mixins";

li + li {
  // Use a kinda vertical rhythm trick so that it looks decent.
  margin-top: calc((1rem - 1em) + 1rem);
  margin-bottom: 1rem;
}

ul,
ol,
dl {
  line-height: core.$baseline;

  @include mixins.strip-leading-trailing-margins;
}

dl {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  overflow: visible;
  margin-bottom: core.$baseline;
}

dt {
  flex: 0 0 40%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
}

dd {
  margin-left: auto;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 0 0 60%
}
