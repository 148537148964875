@use "../0-settings/colors";
@use "../0-settings/core";
@use "../1-tools/functions";

.c-check-list {
  list-style: none;
  padding-left: 2em;
  margin-bottom: core.$baseline;

  li {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 1em; 
      width: 1em; 
      background-image:url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#{functions.url-encoded-color(colors.$color-turquoise-dark)}" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"/></svg>');
      background-size: contain;
      background-repeat: no-repeat;
      left: -1.5em;
      top: .3em;
    }
  }

}
