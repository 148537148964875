/* #### Generated By: http://www.cufonfonts.com #### */
@font-face {
  font-family: 'Roboto Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Regular'), url('/assets/fonts/roboto/Roboto-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Black';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Black'), url('/assets/fonts/roboto/Roboto-Black.woff') format('woff');
  font-display: swap;
}
