@use "sass:color";
@use "../0-settings/colors";

@mixin button($bg) {
	font: inherit;
	border: none;
	appearance: none;
	-webkit-appearance: none;
	padding: .5em;
	background-color: $bg;
	&:hover {
		background-color: color.adjust($bg, $lightness: -15%);
	}
}

.c-button {
  @include button(colors.$color-blue);
  color: colors.$color-white;
  &:visited {
    color: colors.$color-white;
  }
}
