@use "../0-settings/colors";
@use "../1-tools/mixins";

.c-tags-list {
	display: inline-flex;
	flex-wrap: wrap;
	padding: 0;
	margin-top: 0;
	margin-bottom: 0;
	list-style-type: none;
	font-size: 16px;

	li {
		margin: 0 0 .3em 0;
	}

	a {
		font-weight: normal;
		color: colors.$color-turquoise-dark;
		@include mixins.code;
		padding: .15em .5em;
		white-space: nowrap;
	}

	a:focus {
		outline: 0.125rem solid colors.$color-yellow;
	}

	a:not(:hover):not(:focus) {
		border: none;
	}

	li:not(:last-child) {
		padding-right: .5em;
	}
}

