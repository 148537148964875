@use "../0-settings/core";

b,
strong {
  font-weight: normal;
  font-family: core.$black-font-stack;
}

nnbsp {
	// FIX Narrow non-breaking space issue (https://morgan.cugerone.com/blog/troubleshooting-narrow-non-breaking-space-not-rendering)
	font-weight: normal; /* Force Regular type rather than Bold. */
}
