@use "../0-settings/colors";
@use "../0-settings/core";

.c-footer {
  margin: 0;
}

  .c-footer__nav {
    padding: core.$baseline;
    background-color: colors.$color-black-light;

    a,
    a:hover,
    a:visited {
      color: colors.$color-turquoise-light;
    }

	svg {
		fill: currentColor;
	}
  }

  .c-footer__copyright {
    padding: core.$baseline*0.5;
    background-color: colors.$color-turquoise-dark;
    color: colors.$color-white;
    text-align: center;
  }
