@use "../0-settings/colors";

.c-post {
	img {
		max-width: 100%;
		height: auto;
	}
}

.c-post-header {
	margin-bottom: 3rem;

	& > * {
		margin-bottom: .8rem;
	}
}

.c-post-date {
	display: block;
	color: colors.$color-black;
	font-size: 16px;
	margin: .5em 0;
}

.c-post-date--dimmed {
	margin-top: 0;
	color: colors.$color-gray;
}

