.o-links-list {
  display:flex;
  flex-wrap:wrap;
  list-style-type:none;
  justify-content:space-around;
  align-items: center;
  padding: 0;

  // Overrides default margin.
  li + li {
    margin: 0
  }

  li {
    padding: .5em;
  }
}

.o-links-list--end {
  justify-content: flex-end;
}

.o-links-list--column {
  flex-direction: column;
  li {
    padding: 0.25em 0;
  }
}
